import React, { useState, useEffect, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserProfileComponent from './user/profile';
import AddMoney from './user/addMoney';
import MainDashboard from './dashboard';
import Product from './product';
import Transactions from './transactions';
import Earnings from './earning';
import WithdrawMoneyComponent from './withdraw-money';
import AdApplicationReview from './ad-application-review';
import InvitationValidation from './invitationLink/invitationPage';
import NotFound from './common/notFound';
import AdminLogin from './auth/admin-login';
import InitialSignup from './auth/initial-signup';
import NewAddADComponent from './onboarding';
import ForgotPassword from './forgot-password';
import ProtectedRoutes from './auth/ProtectedRoutes';
import Dashboard from './new-dashboard';
import AddNewSubscriber from './add-new-subscriber';

const AppRoutes = () => {
  const user = useSelector((state) => state.user);
  const [selectedAdminSubmenu, setSelectedAdminSubmenu] = useState(null);
  const [selectedDistributor, setSelectedDistributor] = useState(null);
  useEffect(() => {
    if (user?.roleId?.name === 'Admin') {
      setSelectedAdminSubmenu('MD Management');
    } else if (user?.roleId?.name === 'Master Distributor') {
      setSelectedAdminSubmenu('AD Management');
    } else if (user?.roleId?.name === 'Authorized Distributor') {
      setSelectedAdminSubmenu('Subscriber Management');
    } else {
      setSelectedAdminSubmenu(null);
    }
  }, [user]);
  return (
    <Suspense>
      <Routes>
        {/* Public routes */}
        <Route
          path="/"
          element={user?.isLoggedIn ? <Navigate to="/dashboard" /> :<Navigate to="/login" /> }
        />

        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        {/*  */}
        <Route path="/sign-up" element={<InvitationValidation />} />
        <Route path="/login" element={<InitialSignup />} />
        <Route path="/add-subscriber" element={<AddNewSubscriber />} />
        <Route path="/sign-in" element={<InitialSignup type={"signup"} />} />
        <Route path="/new-dashboard" element={<Dashboard />} />

        {/* Protected routes */}
        <Route
          path="/new-onboarding"
          element={
            // <ProtectedRoutes>
            <NewAddADComponent />

            // </ProtectedRoutes>
          }
        />
        <Route
          path="/enter-details"
          element={
            // <ProtectedRoutes>
            <NewAddADComponent />

            // </ProtectedRoutes>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoutes>
              <MainDashboard
                selectedAdminSubmenu={selectedAdminSubmenu}
                setSelectedAdminSubmenu={setSelectedAdminSubmenu}
                selectedDistributor={selectedDistributor}
                setSelectedDistributor={setSelectedDistributor}
              />
            </ProtectedRoutes>
          }
        />
        {}
        <Route
          path="/profile"
          element={
            <ProtectedRoutes>
              <UserProfileComponent />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/add-money"
          element={
            <ProtectedRoutes>
              <AddMoney />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/product"
          element={
            <ProtectedRoutes>
              <Product setSelectedAdminSubmenu={setSelectedAdminSubmenu} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/transactions"
          element={
            <ProtectedRoutes>
              <Transactions
                selectedAdminSubmenu={selectedAdminSubmenu}
                setSelectedAdminSubmenu={setSelectedAdminSubmenu}
              />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/withdraw-money"
          element={
            <ProtectedRoutes>
              <WithdrawMoneyComponent setSelectedAdminSubmenu={setSelectedAdminSubmenu} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/earning"
          element={
            <ProtectedRoutes>
              <Earnings setSelectedAdminSubmenu={setSelectedAdminSubmenu} />
            </ProtectedRoutes>
          }
        />
        {user?.roleId?.name === 'Admin' && (
          <Route
            path="/ad-application-review/:id"
            element={
              <AdApplicationReview
                type="adApplicationReview"
                setSelectedAdminSubmenu={setSelectedAdminSubmenu}
                setSelectedDistributor={setSelectedDistributor}
              />
            }
          />
        )}
        {user?.roleId?.name === 'Authorized Distributor' && (
          <Route
            path="/ad"
            element={
              // <ProtectedRoutes>
                <NewAddADComponent />
              // </ProtectedRoutes>
            }
          />
        )}
        {user?.roleId?.name === 'Master Distributor' && (
          <Route
            path="/md"
            element={
              // <ProtectedRoutes>
                <NewAddADComponent  />
              // </ProtectedRoutes>
            }
          />
        )}
        {/* Fallback route for not found pages */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};
export default AppRoutes;
