import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Sidebar from './sidebar';
import DashboardComponent from './dashboard';
import Header from './header';
import WalletService from '../services/wallet';

const Dashboard = () => {
    const user = useSelector((state) => state.user);
    const [selectedSidebarTab, setSelectedSidebarTab] = useState(0);
    const [dropdownQuery, setDropdownQuery] = useState('MDN');
    const [searchInput, setSearchInput] = useState("");
    const [paginationQuery, setPaginationQuery] = useState({ currentPage: 1, rowsPerPage: 10, totalRows: 0 });
    const [latestSubscribersData, setLatestSubscribersData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchLatestSubscribersData = async () => {
        setLoading(true);
        try {
            const queryParams = `?skip=${(paginationQuery.currentPage - 1) * paginationQuery.rowsPerPage}&limit=${paginationQuery.rowsPerPage}&${dropdownQuery}=${searchInput}`;
            const response = await new WalletService().getActiveSubscribersData(user?._id, queryParams);
            setLoading(false);
            if (response) {
                setLatestSubscribersData(response?.subscribersList);
                setPaginationQuery({ ...paginationQuery, totalRows: response?.totalCount });
            }
        } catch (error) {
            console.error('Error fetching latest subscribers data:', error);
            setLoading(false);
        }
    };

    return (
        <div className='min-h-screen w-full bg-[#E9F9FF] flex justify-end font-jakarta'>
            <Sidebar selectedSidebarTab={selectedSidebarTab} setSelectedSidebarTab={setSelectedSidebarTab} />

            <Header dropdownQuery={dropdownQuery} setDropdownQuery={setDropdownQuery} searchInput={searchInput} setSearchInput={setSearchInput} fetchLatestSubscribersData={fetchLatestSubscribersData} />

            <div className='w-[78%] mt-[84px] py-[24px] px-[4%] flex flex-col gap-[24px]'>
                {selectedSidebarTab === 0 && <DashboardComponent latestSubscribersData={latestSubscribersData} fetchLatestSubscribersData={fetchLatestSubscribersData} paginationQuery={paginationQuery} setPaginationQuery={setPaginationQuery} loading={loading} />}
            </div>
        </div>
    )
}

export default Dashboard;