import React, { useState } from 'react';
import UserService from '../services/user';
import { TbLoader3 } from 'react-icons/tb';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PendingAD({ onClose, distributor, showApproveDistributor, handleSearch }) {
  const [transactions, setTransactions] = useState([]);
  const [showApproveDistruber, setShowApproveDistruber] = useState(showApproveDistributor);
  const [rejectButtonLoading, setRejectButtonLoading] = useState(false);
  const [approveButtonLoading, setApproveButtonLoading] = useState(false);

  const handleDistributorApproval = () => {
    setShowApproveDistruber(true);
  };

  const handleApprove = async (selectedStatus) => {
    selectedStatus === "Approved" ? setApproveButtonLoading(true) : setRejectButtonLoading(true);
    try {
      let userId = distributor?._id;
      let payload = {
        email: distributor?.email,
        roleId: distributor?.roleId,
        status: selectedStatus === "Approved" ? 'active' : 'rejected',
        userId: distributor?.userId,
        isApprove: selectedStatus === "Approved" ? true : false,
        approvedOn: Date.now(),
      };
      const response = await new UserService().updateUserByAdmin(userId, payload);
      if (response) {
        toast.success(`Distributor ${selectedStatus === "Approved" ? 'approved' : 'rejected'} successfully!`);
        onClose();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
        handleSearch();
      }
    } catch (error) {
      console.error('Error approving distributor:', error);
    } finally {
      selectedStatus === "Approved" ? setApproveButtonLoading(false) : setRejectButtonLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center font-jakarta justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white w-[900px] h-[95%] max-h-[700px] rounded-[24px] shadow-lg p-7">
        <div className="flex justify-end" onClick={onClose}>
          <img onClick={onClose} className="h-6 w-6 cursor-pointer" src="./close.svg" alt="Close" />
        </div>

        {showApproveDistruber ? (
          <div className="flex flex-col w-[351px] h-[464px] font-jakarta mx-auto justify-center">
            <div className="flex flex-col gap-1">
              <h2 className="text-[#1D2224] mb-5 font-bold text-ft21">Add a new distributor</h2>
              <h1 className="text-[#1D2224] font-semibold text-[31px]">{distributor?.name ? distributor?.name : "N/A"}</h1>
              <p className="text-[#1D2224]">{distributor?.email}</p>
              <p className="text-[#1D2224]">Added by {distributor?.invitedBy?.name}</p>
            </div>
            <div className="w-full mt-10 flex gap-2">
              <button
                onClick={() => handleApprove("Rejected")}
                className="bg-[#FBDEDF] hover:bg-opacity-85 h-12 w-full text-[#E32028] px-5 py-2 rounded-2xl flex justify-center items-center"
                disabled={rejectButtonLoading}
              >
                {rejectButtonLoading ? <TbLoader3 className="animate-spin" /> : 'Decline'}
              </button>
              
              <button
                onClick={() => handleApprove("Approved")}
                className="bg-[#0F9E73] hover:bg-opacity-85 h-12 w-full text-white px-5 py-2 rounded-2xl flex justify-center items-center "
                disabled={approveButtonLoading}
              >
                {approveButtonLoading ? <TbLoader3 className="animate-spin" /> : 'Approve'}
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="flex items-center mb-4">
              <span className="bg-[#C5EAF7] text-[#064C64] px-3 py-1 rounded-full">
                Authorized Distributor
              </span>
            </div>
            <div className="flex justify-between items-center mb-6">
              <div className="flex flex-col gap-1">
                <div className="flex gap-3">
                  <h1 className="text-[#1D2224] font-bold text-2xl">{distributor?.name ? distributor?.name : "N/A"}</h1>
                  <span className="bg-[#FBDEDF] text-[#B3161D] px-3 py-1 rounded-full">
                    {distributor?.status}
                  </span>
                </div>
                <p className="text-[#757575]">{distributor?.email}</p>
                <p className="text-[#757575]">{distributor?.status}</p>
              </div>
              <button
                onClick={handleDistributorApproval}
                className="flex items-center text-white mt-4 gap-1 bg-[#0EA8DE] px-6 py-3 rounded-[24px] w-fit cursor-pointer"
              >
                Approve Distributor
              </button>
            </div>

            <div>
              <h2 className="text-[#1D2224] font-semibold text-[31px] mb-1">Transactions</h2>
              <div className="">
                <table className="min-w-full bg-white">
                  <thead>
                    <tr>
                      <th className="py-2 text-[#6C6F71] text-ft1_5 font-normal text-left">User</th>
                      <th className="py-2 text-[#6C6F71] text-ft1_5 font-normal text-left">
                        SIM Number
                      </th>
                      <th className="py-2 text-[#6C6F71] text-ft1_5 font-normal text-left">Date</th>
                      <th className="py-2 text-[#6C6F71] text-ft1_5 font-normal text-left">
                        Wireless Plan
                      </th>
                      <th className="py-2 text-[#6C6F71] text-ft1_5 font-normal text-left">
                        AD Commission
                      </th>
                      <th className="py-2 text-[#6C6F71] text-ft1_5 font-normal text-left">
                        My Commission
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-jakarta font-normal">
                    {transactions.length === 0 ? (
                      <tr>
                        <td colSpan="6" className="text-center py-10 text-[#999C9C]">
                          All transactions will be shown here
                        </td>
                      </tr>
                    ) : (
                      transactions.map((transaction, index) => (
                        <tr key={index}>
                          <td className="py-2">{transaction?.user}</td>
                          <td className="py-2">{transaction?.simNumber}</td>
                          <td className="py-2">{transaction?.date}</td>
                          <td className="py-2">{transaction?.wirelessPlan}</td>
                          <td className="py-2">{transaction?.adCommission}</td>
                          <td className="py-2">{transaction?.myCommission}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default PendingAD;