import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { saveAs } from 'file-saver';
import DistributorDetails from '../popups/DMdetailsModal';
import PendingAD from '../popups/pendingAD';
import { TbLoader3 } from 'react-icons/tb';
import AuthorizedDistributorDetails from '../popups/adDetailsModal';
import debounce from 'lodash.debounce';
import Utils from '../utility';
import { BiExport } from 'react-icons/bi';
import { useSelector } from 'react-redux';

const DMTableComp = ({
  title,
  tableRole,
  distributors,
  setDistributors,
  totalDistributors,
  pagination,
  setPagination,
  loading,
  selectedDistributor,
  setSelectedDistributor,
  handleSearch
}) => {
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isPendingADModalOpen, setIsPendingADModalOpen] = useState(false);
  const [showApproveDistributor, setShowApproveDistributor] = useState(false);
  const [paginationDropdownOpen, setPaginationDropdownOpen] = useState(false);
  const paginationRef = useRef(null);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    let handler = (e) => {
      if (paginationRef.current && !paginationRef.current.contains(e.target)) {
        setPaginationDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
  }, []);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (pagination.sortConfig.key === key && pagination.sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setPagination((prev) => ({ ...prev, sortConfig: { key, direction } }));
  };

  const sortedDistributors = useMemo(() => {
    const { key, direction } = pagination.sortConfig;
    if (!key) return distributors;
    return [...distributors].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  }, [distributors, pagination.sortConfig]);

  const filteredDistributors = useMemo(() => {
    return sortedDistributors?.filter(
      (distributor) =>
        distributor.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        distributor.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        distributor.status?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [sortedDistributors, searchTerm]);

  const handleNextPage = () => {
    if (pagination.currentPage * pagination.rowsPerPage < totalDistributors) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };

  const handlePrevPage = () => {
    if (pagination.currentPage > 1) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const exportMasterDistributorsToCSV = () => {
    const headers = ['Name', 'Email', 'Total Ad', 'Total Subscribers', 'Added On', 'Status'];
    const rows = filteredDistributors.map((dist) => [
      dist?.name ? dist?.name : 'N/A',
      dist?.email,
      dist?.totalADs,
      dist?.totalSubscribers,
      `"${Utils.formatTimestamp(dist?.addedOn)}"`, // Wrap in double quotes
      dist?.status,
    ]);
    const csvContent = [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'master distributors.csv');
  };

  const exportAuthorizedDistributorsToCSV = () => {
    const headers = ['Name', 'Email', 'Total Subscribers', 'Added On', 'Status'];
    const rows = filteredDistributors.map((dist) => [
      dist?.name ? dist?.name : 'N/A',
      dist?.email,
      dist?.totalSubscribers,
      `"${Utils.formatTimestamp(dist?.addedOn)}"`, // Wrap in double quotes
      dist?.status,
    ]);
    const csvContent = [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'authorized distributors.csv');
  };

  const handleRowsPerPageChange = (event) => {
    setPagination({
      ...pagination,
      rowsPerPage: Number(event.target.value),
      currentPage: 1,
    });
  };

  const handleRowClick = (distributor) => {
    if (distributor?.status === 'active') {
      setSelectedDistributor(distributor);
    }
    else if (distributor?.status === "information_submitted") {
      // navigate(`/ad-application-review/${distributor?._id}`);
    }
    else if (distributor?.status === "pending") {
      setIsPendingADModalOpen(true);
      setShowApproveDistributor(false);
    }
    else {
      setIsPendingADModalOpen(false);
      setShowApproveDistributor(false);
    }
  };

  const handleApprove = (distributor) => {
    setSelectedDistributor(distributor);
    setIsPendingADModalOpen(true);
    setShowApproveDistributor(true);
  };

  const closeModal = () => {
    setSelectedDistributor(null);
    setIsPendingADModalOpen(false);
    setShowApproveDistributor(false);
  };

  const debouncedSearch = useCallback(
    debounce((value) => setSearchTerm(value), 300),
    []
  );

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="p-4 font-jakarta">
      <div className="flex justify-between">
        <h2 className="text-[25px] font-bold mb-4">
          {title ? `${title} (${totalDistributors})` : 'All Distributors'}
        </h2>

        <div className="flex items-center justify-center bg-[#DDDEDE] hover:bg-opacity-85 rounded-[24px] text-[#1D2224] px-4 gap-2 py-2 h-12">
          <BiExport className="size-6" />
          <button
            onClick={
              tableRole === 'Authorized Distributor'
                ? exportAuthorizedDistributorsToCSV
                : exportMasterDistributorsToCSV
            }>
            Export To CSV
          </button>
        </div>
      </div>

      <div className="overflow-x-auto relative">
        <table className="w-full border-collapse relative">
          <thead className="bg-[#F1F2F2]">
            <tr className="text-ft1_5 font-normal text-[#6C6F71] uppercase">
              {(tableRole === 'Authorized Distributor'
                ? ['Name', 'Email', 'Total Subscribers', 'Added On', 'Status']
                : ['Name', 'Email', 'Total Ad', 'Total Subscribers', 'Added On', 'Status']
              ).map((key) => (
                <th
                  key={key}
                  className="text-left p-2 cursor-pointer"
                  onClick={() => requestSort(key)}>
                  {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                </th>
              ))}
            </tr>
          </thead>

          {loading ? (
            <div className="flex justify-center items-center w-full h-80">
              <TbLoader3 className="animate-spin text-black h-13 w-13 absolute top-50per left-45per" />
            </div>
          ) : (
            <tbody>
              {filteredDistributors.length > 0 ? (
                filteredDistributors.map((distributor, index) => (
                  <tr
                    key={index}
                    className={`${distributor.status !== 'Pending' ? 'hover:rounded-[28px] hover:cursor-pointer hover:bg-white' : ''}`}
                    onClick={() => handleRowClick(distributor)}>
                    <td className="py-4 px-2">
                      {distributor?.firstName
                        ? distributor?.firstName + ' ' + distributor?.lastName
                        : 'N/A'}
                    </td>
                    <td className="py-4 px-2">{distributor?.email}</td>
                    {tableRole === 'Master Distributor' && (
                      <td className="py-4 px-2">{distributor?.totalADs}</td>
                    )}
                    <td className="py-4 px-2">{distributor?.totalSubscribers}</td>
                    <td className="py-4 px-2">{Utils.formatTimestamp(distributor?.addedOn)}</td>
                    <td className="py-4 px-2">
                      <span
                        className={`px-2 py-1 rounded-full font-normal capitalize ${
                          distributor.status === 'active'
                            ? 'bg-[#D1FBEE] text-[#0C7B59]'
                            : distributor.status === 'pending'
                              ? 'bg-[#FBDEDF] text-[#B3161D]'
                              : distributor.status === 'rejected'
                                ? 'bg-[#F8D7DA] text-[#B3161D]'
                                : 'bg-[#DDDEDE] text-[#6C6F71]'
                        }`}>
                        {distributor?.status
                          ? distributor?.status === 'pending'
                            ? 'Pending Approval'
                            : distributor?.status === 'information_submitted'
                              ? 'Information Submitted'
                              : distributor?.status === 'active'
                                ? 'Active'
                                : distributor?.status
                          : 'Inactive'}
                      </span>
                    </td>
                    {user?.roleId?.name === 'Admin' &&
                      distributor.status === 'pending' &&
                      tableRole === 'Authorized Distributor' && (
                        <td className="py-4 px-2">
                          <button
                            className="px-4 py-2 bg-Primary-400  hover:bg-Primary-500 text-black rounded-[24px]  "
                            onClick={() => handleApprove(distributor)}>
                            Approve
                          </button>
                        </td>
                      )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-3">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>

      <div className="flex justify-end items-center mt-4">
        <div className="flex items-center gap-10">
          <div className="flex items-center">
            <span>Rows per page:</span>
            <div
              className="relative"
              onClick={() => setPaginationDropdownOpen(!paginationDropdownOpen)}
              ref={paginationRef}>
              <img
                src="./chevron_backward.svg"
                alt="Chevron-Down Icon"
                className={`absolute right-[15px] top-[17px] transition-all ${paginationDropdownOpen ? 'rotate-180' : ''}`}
                style={{ transitionDuration: '100ms' }}
              />
              <select
                value={pagination.rowsPerPage}
                onChange={handleRowsPerPageChange}
                className="ml-2 py-2 pl-[16px] w-[68px] h-[40px] outline-none border-none rounded-[24px] appearance-none">
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            </div>
          </div>
          <span>
            {(pagination.currentPage - 1) * pagination.rowsPerPage + 1} -{' '}
            {pagination.currentPage * pagination.rowsPerPage > totalDistributors
              ? totalDistributors
              : pagination.currentPage * pagination.rowsPerPage}{' '}
            of {totalDistributors}
          </span>
          <div>
            <button className="p-2 rounded-lg mr-2" onClick={handlePrevPage}>
              &lt;
            </button>
            <button className="p-2 rounded-lg" onClick={handleNextPage}>
              &gt;
            </button>
          </div>
        </div>
      </div>

      {selectedDistributor && selectedDistributor.role.name === 'Master Distributor' && (
        <DistributorDetails
          onClose={closeModal}
          selectedDistributor={selectedDistributor}
          distributors={distributors}
          setDistributors={setDistributors}
        />
      )}

      {selectedDistributor && selectedDistributor.role.name === 'Authorized Distributor' && (
        <AuthorizedDistributorDetails
          onClose={closeModal}
          selectedDistributor={selectedDistributor}
          distributors={distributors}
          setDistributors={setDistributors}
        />
      )}

      {selectedDistributor?.status === 'pending' && isPendingADModalOpen && (
        <PendingAD
          onClose={closeModal}
          distributor={selectedDistributor}
          showApproveDistributor={showApproveDistributor}
          handleSearch={handleSearch}
        />
      )}
    </div>
  );
};

export default DMTableComp;