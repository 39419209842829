import React from 'react';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import WalletService from '../services/wallet';
import { debounce } from '../utils/Debounce';

function SubscriptionDetails({ formData, setFormData, handleChange, setSelectedTab }) {
  const handleInputChange = (e) => {
    const value = e.target.value;

    setFormData((prevData) => ({
      ...prevData,
      oldNumber: value,
    }));
    debouncedPortInCheck();
  };
  const debouncedPortInCheck = debounce(async () => {
    try {
      const requestData = {
        msisdn: formData.oldNumber,
        zipCode: formData.zipCode,
      };
      const response = await new WalletService().portInCheck(requestData);

      if (response.message) {
        console.log(response.message);
      }
    } catch (error) {
      console.error('Error checking eligibility', error);
    }
  }, 2000); // 1000ms debounce delay
  return (
    <>
      <div className="flex flex-col gap-10 xxl:gap-12 w-full max-w-[480px] h-full">
        <FormSectionTitle
          heading={'Select subscription options'}
          subHeading={'Choose between port-in an existing number or getting a new SIM'}
        />
        <div className="relative flex items-center justify-start gap-2 rounded-[3rem] bg-white mr-auto w-max">
          {/* Background slider */}
          <div
            className={`absolute top-0 bottom-0 left-0 w-1/2 bg-Neutral-900 rounded-[3rem] transition-all duration-300 ${formData.portingType === 'Port in' ? 'translate-x-full' : 'translate-x-0'}`}></div>

          {/* Buttons */}
          <button
            className={`relative py-3 px-8 rounded-[27px] transition-all duration-300 z-10 ${formData.portingType === 'New Sim' ? 'text-Primary-400 font-bold' : 'text-Neutral-500'}`}
            onClick={() => setFormData((prev) => ({ ...prev, portingType: 'New Sim' }))}>
            New Sim
          </button>
          <button
            className={`relative py-3 px-8 rounded-[27px] transition-all duration-300 z-10 ${formData.portingType === 'Port in' ? 'text-Primary-400 font-bold' : 'text-Neutral-500'}`}
            onClick={() => setFormData((prev) => ({ ...prev, portingType: 'Port in' }))}>
            Port in
          </button>
        </div>

        {formData.portingType === 'Port in' && (
          <div className="flex flex-col gap-8">
            <div className="flex flex-col items-start gap-2">
              <label htmlFor="oldNumber" className="text-Neutral-500">
                Old number
              </label>
              <input
                id="oldNumber"
                type="text"
                placeholder="Enter your old number"
                value={formData.oldNumber}
                onChange={handleInputChange}
                className={`h-12 w-full bg-white focus:bg-white px-4 py-4.5 rounded-xl border
                    border-Neutral-100 outline-none text-Neutral-900 focus:border-2 focus:border-Primary-500`}
              />
            </div>
          </div>
        )}
        <div className="flex justify-between items-center   mb-16 mt-auto">
          <button className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Neutral-900"
                  onClick={() => setSelectedTab((prev) => prev - 1)}>
            Back
          </button>
          <button
            className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
            onClick={() => setSelectedTab((prev) => prev + 1)}>
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default SubscriptionDetails;
