import React from 'react';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import CustomInputField from '../common/onboarding/CustomInputField ';

function PersonalDetails({selectedTab , formData, setFormData, handleChange, setSelectedTab }) {
  return (
    <>
      <div className="flex flex-col gap-10 xxl:gap-12 w-full max-w-[480px] h-full">
        <FormSectionTitle
          heading={'Subscriber’s Personal details'}
          subHeading={'Enter basic information about the new subscriber.'}
        />
        <div className="flex flex-col gap-8">
         
            <CustomInputField
              id={'name'}
              labelText={'Name'}
              type={"text"}
              placeholder={'Enter your name'}
              value={formData.name}
              onChange={handleChange}
            />
            <CustomInputField
              id={'contactNumber'}
              labelText={'Contact number'}
              type={"number"}
              placeholder={'Enter your contact number'}
              value={formData.contactNumber}
              onChange={handleChange}
            />
            <CustomInputField
              id={'email'}
              labelText={'Email'}
              type={"text"}
              placeholder={'Enter your email'}
              value={formData.email}
              onChange={handleChange}
            />
        </div>
        <div className="flex justify-between items-center   mb-16 mt-auto">
        <button
            className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Neutral-900"
         >
          Back
        </button>
        <button
            className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
           
            onClick={() => setSelectedTab((prev) => prev + 1)}
            > Next
        </button>
      </div>
      </div>
   
    </>
  );
}

export default PersonalDetails;
