import React, { useState, useEffect } from 'react';
import SubscriberTableComp from './subscriberTable';
import ADTableComp from './AdTable';
import DMTableComp from './DMTable';
import Utils from '../utility';
import { useDispatch, useSelector } from 'react-redux';
import { removeUser } from '../slices/userSlice';
import WalletService from '../services/wallet';

const ADManagement = ({
  subscribers,
  setSubscribers,
  searchTerm,
  distributors,
  setDistributors,
  setDropdownQuery,
  setSearchInput,
  adDistributorsLoading,
  totalDistributors,
  pagination,
  setPagination,
  setTotalDistributors,
  selectedDistributor,
  setSelectedDistributor,
  handleSearch
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const [buttonText, setButtonText] = useState('');
  const [adminCardsData, setAdminCardsData] = useState({
    totalSubscribers: 0,
    orders: 0,
    conversionRate: 0,
  });
  const [subscriberManagementData, setSubscriberManagementData] = useState([
    { title: 'Total Subscribers', subValue: 'All time', value: 0 },
    { title: 'Earnings', subValue: 'This week', value: '$0' },
    { title: 'New Subscribers', subValue: 'Weekly Report', value: '0' },
  ]);
  const [MDCardsData, setMDCardsData] = useState([
    { title: 'Total AD', subValue: 'All time', value: 0 },
    { title: 'Earnings', subValue: 'This week', value: '$0' },
    { title: 'Total Subscribers', subValue: 'Weekly Report', value: 0 },
  ]);

  useEffect(() => {
    setDropdownQuery('name');
    setSearchInput('');
    setPagination({
      currentPage: 1,
      rowsPerPage: 10,
      sortConfig: { key: null, direction: 'ascending' },
    });
    setTotalDistributors(0);
  }, []);

  useEffect(() => {
    switch (user?.roleId?.name) {
      case 'Authorized Distributor':
        setButtonText('Add Subscriber');
        break;
      case 'Master Distributor':
        setButtonText('Add New AD');
        break;
      case 'Admin':
        setButtonText('Add Master Distributor');
        break;
      default:
        setButtonText('');
    }
  }, [user?.roleId?.name]);

  useEffect(() => {
    async function fetchData() {
      try {
        // CHECKING IF TOKEN IS EXPIRE OR NOT
        if (Utils.checkTokenExpireOrNot()) {
          dispatch(removeUser({}));
          return;
        }

        if (user?.roleId?.name === 'Authorized Distributor') {
          const response = await new WalletService().fetchDistributorSummary(user?._id);

          setSubscriberManagementData([
            {
              title: 'Total Subscribers',
              subValue: 'All time',
              value: response.totalSubscribersCount,
            },
            {
              title: 'Earnings',
              subValue: 'This week',
              value: `$${response.earnings}`,
            },
            {
              title: 'New Subscribers',
              subValue: 'Weekly Report',
              value: response.weeklySubscribersCount,
            },
          ]);
        } else if (user?.roleId?.name === 'Master Distributor') {
          const response = await new WalletService().fetchDistributorSummary(user?._id);

          setMDCardsData([
            {
              title: 'Total AD',
              subValue: 'All time',
              value: response?.activeAuthorizedDistributorsCount,
            },
            {
              title: 'Earnings',
              subValue: 'This week',
              value: `$${response?.earnings}`,
            },
            {
              title: 'Total Subscribers',
              subValue: 'Weekly Report',
              value: response?.weeklySubscribersCount,
            },
          ]);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [dispatch, user?._id, user?.roleId?.name]);

  useEffect(() => {
    async function fetchData() {
      try {
        if (user?.roleId?.name === 'Admin') {
          // CHECKING IF TOKEN IS EXPIRE OR NOT
          if (Utils.checkTokenExpireOrNot()) {
            dispatch(removeUser({}));
            return;
          }

          const subscribersResponse = await new WalletService().getWeeklySubscribersSummary();
          const ordersResponse = await new WalletService().getWeeklyOrdersSummary();

          setAdminCardsData((prevData) => ({
            ...prevData,
            orders: ordersResponse?.totalAmount,
            totalSubscribers: subscribersResponse?.count,
          }));
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [dispatch, user?.roleId?.name]);

  const renderAdminCards = () => (
    <div className="grid grid-cols-3 gap-6 mb-4">
      {[
        { title: 'Total Subscribers', value: adminCardsData.totalSubscribers },
        { title: 'Orders', value: `$${adminCardsData.orders}` },
        // { title: 'Conversion Rate', value: `${adminCardsData.conversionRate}%`,  }
      ].map((card, index) => (
        <div key={index} className="bg-white px-6 py-6 rounded-[24px] flex justify-between">
          <div className="flex flex-col">
            <span className="text-lg font-semibold text-[#1D2224]">{card.title}</span>
            <span className="text-sm font-normal text-[#6C6F71]">Weekly Report</span>
            <div className="text-2xl font-bold mt-6">{card.value}</div>
          </div>
          {/* <div>
            <img src={card.imgSrc} alt={card.title} className="" />
          </div> */}
        </div>
      ))}
    </div>
  );

  const renderADCards = () => (
    <div className="grid grid-cols-3 gap-6 mb-4">
      {subscriberManagementData.map((card, index) => (
        <div key={index} className="bg-white px-6 py-6 rounded-[24px] flex justify-between">
          <div className="flex justify-between w-full ">
            <div className="flex flex-col">
              <span className="text-lg font-semibold text-[#1D2224]">{card.title}</span>
              <span className="text-sm font-normal text-[#6C6F71]">{card.subValue}</span>
            </div>
            <div className="text-[31px] font-jakarta text-[#1D2224] font-extrabold ">
              {card.value}
            </div>
          </div>
          {/* <div>
              <img src={card.imgSrc} alt={card.title} className="" />
            </div> */}
        </div>
      ))}
    </div>
  );

  const renderMDCards = () => (
    <div className="grid grid-cols-3 gap-6 mb-4">
      {/* , imgSrc: '/chart.svg' */}
      {MDCardsData.map((card, index) => (
        <div key={index} className="bg-white px-6 py-6 rounded-[24px] flex justify-between">
          <div className="flex justify-between w-full ">
            <div className="flex flex-col">
              <span className="text-lg font-semibold text-[#1D2224]">{card.title}</span>
              <span className="text-sm font-normal text-[#6C6F71]">{card.subValue}</span>
            </div>
            <div className="text-[31px] font-jakarta text-[#1D2224] font-extrabold ">
              {card.value}
            </div>
          </div>
          {/* <div>
              <img src={card.imgSrc} alt={card.title} className="" />
            </div> */}
        </div>
      ))}
    </div>
  );

  return (
    <div className="p-4 z-10 font-jakarta flex-grow min-h-screen bg-[#F1F2F2]">
      <div className="flex flex-col">
        <div className="flex mobile:flex-col items-center justify-between">
          <div className="flex p-4 flex-col">
            <h2 className="md:text-[39px] text-[22px] font-extrabold">AD Management</h2>
            <p className="text-[#6C6F71]">
              Manage all authorized distributors and add new ones at one place.
            </p>
          </div>
        </div>

        {user && user?.roleId?.name === 'Authorized Distributor' && renderADCards()}
        {user && user?.roleId?.name === 'Master Distributor' && renderMDCards()}
        {user && user?.roleId?.name === 'Admin' && renderAdminCards()}
        {user && user?.roleId?.name === 'Master Distributor' && (
          <ADTableComp subscribers={subscribers} searchTerm={searchTerm} />
        )}

        {user && user?.roleId?.name === 'Authorized Distributor' && (
          <SubscriberTableComp
            tableRole="Authorized Distributor"
            subscribers={subscribers}
            setSubscribers={setSubscribers}
            userId={user._id}
            searchTerm={searchTerm}
          />
        )}

        {user && user?.roleId?.name === 'Admin' && (
          <DMTableComp
            tableRole="Authorized Distributor"
            title="All Authorized Distributors"
            subscribers={subscribers}
            searchTerm={searchTerm}
            distributors={distributors}
            setDistributors={setDistributors}
            totalDistributors={totalDistributors}
            pagination={pagination}
            setPagination={setPagination}
            loading={adDistributorsLoading}
            selectedDistributor={selectedDistributor}
            setSelectedDistributor={setSelectedDistributor}
            handleSearch={handleSearch}
          />
        )}
      </div>
    </div>
  );
};

export default ADManagement;
