// Function to format a Date object to 'dd-MM-yyyy'
export const formatDate = (date, formatStr = 'dd-MM-yyyy') => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return '';
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  if (typeof formatStr !== 'string' || formatStr.trim() === '') {
    return ''; // Handle invalid format string
  }

  return formatStr
    .replace('dd', day)
    .replace('MM', month)
    .replace('yyyy', year);
};


// Function to parse a date string into a Date object based on 'dd-MM-yyyy' format
export const parseDate = (dateStr, formatStr = 'dd-MM-yyyy') => {
  if (typeof dateStr !== 'string' || dateStr.trim() === '' || typeof formatStr !== 'string' || formatStr.trim() === '') {
    return null; // Handle invalid input
  }

  const dateParts = dateStr.split(/[-/ ]/);
  const formatParts = formatStr.split(/[-/ ]/);

  if (dateParts.length !== formatParts.length) {
    return null; // Handle mismatched date and format parts
  }

  let day, month, year;

  formatParts.forEach((part, index) => {
    if (part === 'dd') {
      day = parseInt(dateParts[index], 10);
    } else if (part === 'MM') {
      month = parseInt(dateParts[index], 10) - 1; // JS months are zero-indexed
    } else if (part === 'yyyy') {
      year = parseInt(dateParts[index], 10);
    }
  });

  const parsedDate = new Date(year, month, day);

  if (!isNaN(parsedDate) && parsedDate.getDate() === day && parsedDate.getMonth() === month && parsedDate.getFullYear() === year) {
    return parsedDate;
  } else {
    return null; // Invalid date
  }
};

export function isValidDate(dateStr) {
  const [month, day, year] = dateStr.split('-').map(Number);
  const today = new Date();
  const inputDate = new Date(year, month - 1, day);

  // Check if the date is valid
  if (isNaN(inputDate.getTime())) return false;

  // Check if the date is in the correct range
  if (month < 1 || month > 12 || day < 1 || day > 31 || year < 1900 || year > today.getFullYear())
    return false;

  // Check if the date is in the future
  if (inputDate > today) return false;

  // Check if the day is valid for the month
  const daysInMonth = new Date(year, month, 0).getDate();
  if (day > daysInMonth) return false;

  return true;
}

export const calculateAge = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);
  
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  // Adjust age if birth month/day is still ahead in the current year
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

