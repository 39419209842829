import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';

import PersonalDetails from './personal-details';
import AddressDetails from './address-details';
import WalletService from '../services/wallet';
import SubscriptionDetails from './subscription-details';
import DeviceSimDetails from './device-sim-details';
import Sidebar from './sidebar';
import WireLessPlan from './wireless-plan';
import Checkout from './checkout';
import { useNavigate } from 'react-router-dom';

const AddNewSubscriber = () => {
  const user = useSelector((state) => state.user);
  const navigate =useNavigate()

  const [selectedTab, setSelectedTab] = useState(0);

  const [products, setProducts] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const [formData, setFormData] = useState({
    name: '',
    contactNumber: '',
    email: '',
    zipCode: '',
    city: '',
    state: '',
    street: '',
    streetDirection: '',
    portingType: 'New Sim',
    oldNumber: '',
    IMEI: '',
    ICCID: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const fetchProductsData = async () => {
    try {
      const response = await new WalletService().getProductsData();
      const updatedProductsData = [...response.tetherPlans, ...response.nonTetherPlans];

      setProducts(updatedProductsData);
      setSelectedPlan(1);
    } catch (error) {
      console.error('Error while fetching products data', error);
    }
  };

  useEffect(() => {
    fetchProductsData();
  }, []);

  return (
    <div className="mx-auto w-full  min-h-screen flex  bg-[#E9F9FF] relative font-jakarta">
      <div
        className="absolute top-8 right-8 p-3 rounded-lg bg-white hover:cursor-pointer"
        onClick={()=>navigate('/dashboard')
        }>
        <MdClose className="w-5 h-5" />
      </div>
      <div className="hidden lg:block fixed top-0 left-0 h-full lg:w-[22.5rem] xxl:w-[30rem] max-w-[480px] z-40 bg-white overflow-y-auto custom-scrollbar">
        <Sidebar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      </div>
      <div className="flex-1 flex justify-center lg:ml-[22.5rem] xxl:ml-[480px] mt-[5%] xxl:mt-[8%] relative">
  
          {selectedTab === 0 && (
            <PersonalDetails
              selectedTab={selectedTab}
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              setSelectedTab={setSelectedTab}
            />
          )}
          {selectedTab === 1 && (
            <AddressDetails
              selectedTab={selectedTab}
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              setSelectedTab={setSelectedTab}
            />
          )}
          {selectedTab === 2 && (
            <SubscriptionDetails
              selectedTab={selectedTab}
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              setSelectedTab={setSelectedTab}
            />
          )}
          {selectedTab === 3 && (
            <DeviceSimDetails
              selectedTab={selectedTab}
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              setSelectedTab={setSelectedTab}
            />
          )}
          {selectedTab === 4 && (
            <WireLessPlan
              products={products}
              setProducts={setProducts}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              setSelectedTab={setSelectedTab}
            />
          )}
          {selectedTab === 5 && (
            <Checkout
            formData={formData}
            products={products}
            selectedPlan={selectedPlan}
              setSelectedTab={setSelectedTab}
            />
          )}
      </div>
    </div>
  );
};

export default AddNewSubscriber;
