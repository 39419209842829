import { createSlice } from '@reduxjs/toolkit';
import Utils from '../utility';
import { keyConstants } from '../constants';
import Cookies from '../managers/sessionManager';
import history from '../managers/history';
const { USER, ACCESS_TOKEN } = keyConstants;

const user = Utils.localStorageGetItem(USER);
const userData = user ? user : {};

const initialState = {
  _id: userData._id,
  name: userData?.name,
  email: userData?.email,
  roleId: userData?.roleId,
  loading: false,
  isLoggedIn: !!userData?._id,
  isActive: userData?.isActive,
  isDeleted: userData?.isDeleted,
  isApprove: userData?.isApprove,
  rejectReason: userData?.rejectReason,
  rejectComment: userData?.rejectComment,
  status: userData?.status,
  isInformationSubmitted: userData?.isInformationSubmitted,
  firstName: userData?.firstName,
  lastName: userData?.lastName,
  name: userData?.name,
  gender: userData?.gender,
  genderRefer : userData?.genderRefer,
  dob: userData?.dob,
  postalCode: userData?.postalCode,
  identities: userData?.identities,
  proofOfAddress: userData?.proofOfAddress,
  paymentMethods: userData?.paymentMethods,
  bankName: userData?.bankName,
  accountNumber: userData?.accountNumber,
  routingNumber: userData?.routingNumber,
  accountType: userData?.accountType,
  accountHolderName: userData?.accountHolderName,
  zelleDetails: userData?.zelleDetails,
  WireTransferDetails: userData.WireTransferDetails,
  address: userData?.address,
  fatherName: userData?.fatherName,
  identityNo: userData?.identityNo,
  isTermsAccepted: userData?.isTermsAccepted,
  nationality: userData?.nationality,
  isPaymentAdded: userData?.isPaymentAdded,
  phoneCountryCode: userData?.phoneCountryCode,
  phoneNumber: userData?.phoneNumber,
  signatureName: userData?.signatureName,
  signature: userData?.signature,
  dateSigned: userData?.dateSigned,
  isSkip: userData?.isSkip || false,
  currentOnboardingFormIndex: userData?.currentOnboardingFormIndex || 0,
  highestFormIndexCompleted: userData?.highestFormIndexCompleted || 0,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    /**
     * Updates the user state with the provided action payload.
     *
     * @param {Object} state - The current state of the user.
     * @param {Object} action - The action object containing the payload.
     * @return {Object} The updated state with the payload applied.
     */
    updateUser: (state, action) => {
      Utils.localStorageSetItem(USER, action.payload);
      return {
        ...state,
        ...action.payload,
      };
    },
    /**
     * Removes a user from the state and performs additional cleanup tasks.
     *
     * @param {object} state - The current state object.
     * @param {object} action - The action object containing additional information.
     * @returns {object} The updated state object with the user removed.
     */
    removeUser: (state, action) => {
      history.push('/');
      new Cookies().remove(ACCESS_TOKEN);
      Utils.localStorageClear();
      action.payload = Object.fromEntries(Object.keys(state || {}).map((key) => [key, '']));
      action.payload.isLoggedIn = false;
      return {
        ...state,
        ...action.payload,
      };
    },
    showLoader: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    hideLoader: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    mobileSideBar: (state, action) => {
      return {
        ...state,
        isMobileSidebar: action.payload,
      };
    },
    toggleSkip: (state, action) => {
      Utils.localStorageSetItem(USER, {
        ...state,
        isSkip: action.payload,
      });
      return {
        ...state,
        isSkip: action.payload,
      };
    },
    updateOnboardingFormIndex: (state, action) => {
      Utils.localStorageSetItem(USER, {
        ...state,
        currentOnboardingFormIndex: action.payload,
      });
      return {
        ...state,
        currentOnboardingFormIndex: action.payload,
      };
    },
    updateFormIndexCompleted: (state, action) => {
      Utils.localStorageSetItem(USER, {
        ...state,
        highestFormIndexCompleted: action.payload,
      });
      return {
        ...state,
        highestFormIndexCompleted: action.payload,
      };
    },
  },
});

export const {
  updateUser,
  removeUser,
  showLoader,
  hideLoader,
  mobileSideBar,
  toggleSkip,
  updateOnboardingFormIndex,
  updateFormIndexCompleted,
} = userSlice.actions;

export default userSlice.reducer;
