import React from 'react';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';

function WireLessPlan({ products, setProducts, selectedPlan, setSelectedPlan, setSelectedTab }) {
  const handleSelectPlan = (index) => {
    setSelectedPlan(index); // Store the selected product index
  };
  return (
    <>
      <div className="flex flex-col gap-10 xxl:gap-12 w-full max-w-[824px] h-full">
        <FormSectionTitle
          heading={'Select a wireless plan'}
          subHeading={'Choose the most suitable wireless plan for your subscriber.'}
        />
        <div className="grid grid-cols-3 gap-4 max-h-[733px]  overflow-y-scroll">
          {products.length > 0 &&
            products.map((product, index) => (
              <div
                key={index}
                className={`p-4  rounded-3xl cursor-pointer bg-white  ${selectedPlan === index ? 'border-Primary-500 border-2 ' : ''}`}
                onClick={() => handleSelectPlan(index)}>
                {/* Render your product content here */}
                <div className='flex flex-col items-start gap-4'>
                  <p className="font-bold text-Neutral-700 text-base">{product.displayName}</p>
                  <p className='text-Primary-500 font-bold'>{product.size}</p>
                  <p className='text-Neutral-300'>{product.description}</p>
                  <p> <span className='font-bold text-Neutral-700'>$ {product.amount}</span>  / month</p>
                </div>
              </div>
            ))}
        </div>
        <div className="flex justify-between items-center   mb-16 mt-auto">
          <button className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Neutral-900"
           onClick={() => setSelectedTab((prev) => prev - 1)}>
            Back
          </button>
          <button
            className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
            onClick={() => setSelectedTab((prev) => prev + 1)}>
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default WireLessPlan;
