import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowUp, MdOutlineClose } from 'react-icons/md';
import { LuPencilLine } from 'react-icons/lu';
import { FaRegStar } from 'react-icons/fa';
import { FiPause } from 'react-icons/fi';
import { TbLoader3 } from 'react-icons/tb';
import WalletService from '../services/wallet';
import { useSelector } from 'react-redux';
import EditSubscriberDetails from './edit-subscriber-details';

const SubscriberDetails = ({ selectedSubscriberMid }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openEditDetails, setEditDetails] = useState(false);
  const user = useSelector((state) => state?.user);
  const [iccids, seticcids] = useState([]);

  const [subscriberAccountData, setSubscriberAccountData] = useState({
    subscriberFirstName: '',
    subscriberLastName: '',
    email: '',
    contactNumber: '',
    address: { city: '', state: '', zipCode: '' },
    allSubscriptions: [],
  });

  const [subscriptionPlanDetails, setSubscriptionPlanDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subscriptionPlansLoading, setSubscriptionPlansLoading] = useState(false);

  useEffect(() => {
    const fetchSubscriberAccountDetails = async () => {
      try {
        setLoading(true);
        const res = await new WalletService().getSubscriberAccountDetails(selectedSubscriberMid);
        setLoading(false);
        res?.activeSubscriptions.forEach((item) => (item.isOpen = false));
        res?.tempSubscriptions.forEach((item) => (item.isOpen = false));
        res?.activePortingProcesses.forEach((item) => (item.isOpen = false));
        res?.tempPortingProcesses.forEach((item) => (item.isOpen = false));

        let resiccids = [
          ...res.activeSubscriptions.map((item) => ({
            iccid: item.iccid,
            id: item.id,
            subscriptionId: item.subscriptionDbId,
          })),
          ...res.tempSubscriptions.map((item) => ({
            iccid: item.iccid,
            id: item.id,
            subscriptionId: item.subscriptionDbId,
          })),

          ...res.activePortingProcesses.map((item) => ({
            iccid: item.iccid,
            id: item.id,
            subscriptionId: item.subscriptionDbId,
          })),
          ...res.tempPortingProcesses.map((item) => ({
            iccid: item.iccid,
            id: item.id,
            subscriptionId: item.subscriptionDbId,
          })),
        ];

        seticcids((prev) => [...prev, ...resiccids]);

        setSubscriberAccountData({
          ...subscriberAccountData,
          ...res,
          allSubscriptions: [
            ...res.activeSubscriptions,
            ...res.tempSubscriptions,
            ...res.activePortingProcesses,
            ...res.tempPortingProcesses,
          ], // Merge here
        });
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchSubscriberAccountDetails();
  }, []);

  const fetchSubscriptionPlanDetails = async (subscriptionPlanId, subscriptionPlanIndex) => {
    try {
      setSubscriptionPlansLoading(true);
      const res = await new WalletService().getSubscriberPlanDetails(
        selectedSubscriberMid,
        subscriptionPlanId
      );
      setSubscriptionPlansLoading(false);
      const updatedSubscriptionPlanDetails = [...subscriptionPlanDetails];
      updatedSubscriptionPlanDetails[subscriptionPlanIndex] = { ...res };
      setSubscriptionPlanDetails(updatedSubscriptionPlanDetails);
    } catch (error) {
      console.error(error);
      setSubscriptionPlansLoading(false);
    }
  };

  const handleDropdown = (subscriptionPlanId, subscriptionPlanIndex) => {
    fetchSubscriptionPlanDetails(subscriptionPlanId, subscriptionPlanIndex);
    const updatedSubscribersAccountData = { ...subscriberAccountData };
    updatedSubscribersAccountData.allSubscriptions.forEach((item, index) => {
      if (index === subscriptionPlanIndex) {
        updatedSubscribersAccountData.allSubscriptions[subscriptionPlanIndex].isOpen =
          !updatedSubscribersAccountData.allSubscriptions[subscriptionPlanIndex].isOpen;
      }
    });

    setSubscriberAccountData(updatedSubscribersAccountData);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getUTCFullYear();
    return `${day} ${month} ${year}`;
  };

  return (
    <div className={`flex justify-center min-h-screen h-full w-full bg-[#E9F9FF] font-jakarta`}>
      <>
        {!openEditDetails ? (
          <div className="max-w-200.5 w-full flex flex-col gap-4 items-start mt-5">
            <div className="flex gap-2 py-2 px-4  items-center rounded-3xl bg-white">
              <img src="/back-arrow-icon.svg" alt="back-arrow-icon" />
              <p className="text-Neutral-900 font-bold">Back</p>
            </div>
            <div className="w-full bg-white rounded-3xl flex flex-col gap-6 justify-between p-8 mt-5">
              <div className="flex justify-between items-center">
                <h1 className="text-Neutral-900 text-2xl font-extrabold">
                  {subscriberAccountData.subscriberFirstName +
                    ' ' +
                    subscriberAccountData.subscriberLastName}
                </h1>
                <button
                  className="py-3 px-6  rounded-3xl bg-Primary-50 text-black font-bold"
                  onClick={() => setEditDetails(true)}>
                  Edit details
                </button>
              </div>
              <div className="flex flex-col gap-6">
                <div className="flex">
                  <div className="flex gap-2 grow">
                    <div className="h-12 w-12 flex justify-center items-center rounded-xl bg-Primary-50">
                      <img src="/email-icon.svg" alt="email-icon" />
                    </div>
                    <div className="flex flex-col">
                      <p className="text-Neutral-500 text-sm">Email</p>
                      <p className="text-Neutral-900 text-base">{subscriberAccountData.email}</p>
                    </div>
                  </div>
                  <div className="flex gap-2 grow">
                    <div className="h-12 w-12 flex justify-center items-center rounded-xl bg-Primary-50">
                      <img src="/phone-icon.svg" alt="phone-icon" />
                    </div>
                    <div className="flex flex-col">
                      <p className="text-Neutral-500 text-sm">Phone</p>
                      <p className="text-Neutral-900 text-base">
                        {subscriberAccountData.contactNumber}
                      </p>
                    </div>
                  </div>
                </div>
                {/* address  */}
                <div className="flex gap-2">
                  <div className="h-12 w-12 flex justify-center items-center rounded-xl bg-Primary-50">
                    <img src="/location-icon.svg" alt="location-icon" />
                  </div>
                  <div>
                    <p className="text-Neutral-500 text-sm">Address</p>
                    <p className="text-Neutral-900 text-base">
                      {subscriberAccountData?.address?.city +
                        ', ' +
                        subscriberAccountData?.address?.state +
                        ', ' +
                        subscriberAccountData?.address?.zipCode}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full bg-white px-3 pb-3 rounded-3xl">
              <div className="flex justify-between items-center mt-8">
                <p className="text-Neutral-900 text-2xl font-extrabold">Subscriptions</p>
                <button
                  // onClick={() => setIsModalOpen(true)}
                  className="py-3 px-6 bg-Primary-400 rounded-3xl text-black font-bold">
                  Add a subscription
                </button>
              </div>

              {subscriberAccountData?.allSubscriptions.length > 0 ? (
                <div className="flex flex-col w-full mt-6">
                  {subscriberAccountData?.allSubscriptions?.map((item, index) => (
                    <div
                      className={`w-full p-4 flex flex-col rounded-3xl transition-all ${item.isOpen ? 'bg-Primary-50' : 'bg-white'}`}
                      key={index}>
                      <div
                        className="flex items-center justify-between cursor-default"
                        onClick={() => handleDropdown(item?.id, index)}>
                        <div className="flex gap-2 ">
                          <div className="h-12 w-12 flex justify-center items-center rounded-xl bg-Success-100">
                            <img src="/sim_card.svg" alt="sim_card-icon" />
                          </div>
                          <div className="flex flex-col">
                            <p className="text-Neutral-900 font-bold">MDN</p>
                            <p className="text-Neutral-500 text-base">{item.product}</p>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <div
                            className={`rounded-5-5xl flex items-center gap-1  ${item.subscriberNumberStatus === 'Active' ? 'bg-Success-100 p-2 ' : 'bg-Success-300 py-3 px-6'}`}>
                            <div
                              className={`w-2 h-2 rounded-full  ${item.subscriberNumberStatus === 'Active' ? 'bg-Success-500' : 'bg-Neutral-300 hidden'} `}></div>
                            <p
                              className={`font-semibold ${item.subscriberNumberStatus === 'Active' ? 'text-Success-500' : 'text-black'}`}>
                              {item.subscriberNumberStatus || 'Activate'}
                            </p>
                          </div>

                          <MdKeyboardArrowUp
                            className={`h-6 w-6 cursor-pointer text-Neutral-900 transition-all ${item.isOpen ? '' : 'rotate-180'}`}
                          />
                        </div>
                      </div>

                      {item.isOpen && (
                        <>
                          {/* {subscriptionPlanDetails[index]?.features && (
                        <div className="flex flex-col gap-[4px]">
                          <p className="text-[#6C6F71]">Features</p>
                          <div className="flex items-center gap-[4px] flex-wrap">
                            {subscriptionPlanDetails[index]?.features?.map((item, index) => (
                              <div
                                className="px-[12px] py-[4px] bg-[#F1F2F2] rounded-[34px] flex justify-center items-center"
                                key={index}>
                                {item?.name}
                              </div>
                            ))}
                          </div>
                        </div>
                      )} */}

                          <div className="flex gap-4 p-4">
                            <div className=" flex flex-col gap-4 items-start grow">
                              {/* iccid  */}
                              <div className="flex flex-col">
                                <p className="text-Neutral-500 text-sm">ICCID</p>
                                <p className="text-Neutral-700 text-base">
                                  {subscriberAccountData?.allSubscriptions[index]?.iccid || ''}
                                </p>
                              </div>
                              {/* imei  */}
                              <div className="flex flex-col">
                                <p className="text-Neutral-500 text-sm">IMEI</p>
                                <p className="text-Neutral-700 text-base">
                                  {subscriptionPlanDetails[index]?.imei}
                                </p>
                              </div>
                              {/* activation date  */}
                              <div className="flex flex-col">
                                <p className="text-Neutral-500 text-sm">Activated</p>
                                <p className="text-Neutral-700 text-base">
                                  {subscriptionPlanDetails[index]?.activationDate &&
                                    formatDate(subscriptionPlanDetails[index]?.activationDate)}
                                </p>
                              </div>
                            </div>

                            <div className="flex flex-col gap-4 items-start grow">
                              <div className="flex flex-col">
                                <p className="text-Neutral-500 text-sm">BAN</p>
                                <p className="text-Neutral-700 text-base">
                                  {subscriptionPlanDetails[index]?.ban}
                                </p>
                              </div>
                              <div className="flex flex-col">
                                <p className="text-Neutral-500 text-sm">Created</p>
                                <p className="text-Neutral-700 text-base">
                                  {subscriptionPlanDetails[index]?.creationDate &&
                                    formatDate(subscriptionPlanDetails[index]?.creationDate)}
                                </p>
                              </div>
                              <div className="flex flex-col">
                                <p className="text-Neutral-500 text-sm">Next billing</p>
                                <p className="text-Neutral-700 text-base">
                                  {subscriptionPlanDetails[index]?.nextBillCycleDate &&
                                    formatDate(subscriptionPlanDetails[index]?.nextBillCycleDate)}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center gap-2 mt-4">
                            <div
                              onClick={() => setIsModalOpen(true)}
                              className="py-3 pr-5 pl-4 bg-white rounded-3xl flex justify-center items-center  gap-1 text-Primary-500  cursor-pointer">
                              <LuPencilLine className="w-6 h-6" />
                              <p className="font-semibold">Edit</p>
                            </div>

                            <div className="py-3 pr-5 pl-4 bg-white rounded-3xl flex justify-center items-center  gap-1 text-Primary-500  cursor-pointer">
                              <FaRegStar className="w-6 h-6" />
                              <p className="font-semibold">Features</p>
                            </div>

                            <div className="py-3 pr-5 pl-4 bg-white rounded-3xl flex justify-center items-center  gap-1 text-Primary-500  cursor-pointer">
                              <FiPause className="w-6 h-6" />
                              <p className="font-semibold">Suspend</p>
                            </div>

                            <div className="py-3 pr-5 pl-4 bg-white rounded-3xl flex justify-center items-center  gap-1 text-Primary-500  cursor-pointer">
                              <MdOutlineClose className="w-6 h-6" />
                              <p className="font-semibold">Cancel</p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  className="flex justify-center items-center"
                  style={{ width: 'calc(100% - 320px)' }}>
                  <p className="text-[#1D2224] text-[22px] font-bold">
                    No subscriptions for this subscriber
                  </p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <EditSubscriberDetails
            subscriberAccountData={subscriberAccountData}
            setSubscriberAccountData={setSubscriberAccountData}
            setEditDetails={setEditDetails}
          />
        )}
      </>

    </div>
  );
};

export default SubscriberDetails;
