import React from 'react';
import { RxCross2 } from 'react-icons/rx';
import CenterWrapper from './CenterWrapper';
// import { useNavigate } from 'react-router-dom';

function MessageWrapper({ heading, subheading, isError, isbackButtonShow,paragraph=null }) {
  // const navigate = useNavigate();
  return (
    <div className=" w-full min-h-screen h-full flex bg-white font-jakarta">
      <div className="xxl:max-w-[480px] xxl:w-111 hidden lg:flex lg:max-w-[360px] lg:w-90 w-full  justify-between flex-col lg:px-10 lg:pt-4 lg:pb-10 xxl:px-16 xxl:py-6">
        <img src="/tittle-logo.svg" alt="EZ-CRM Logo" className="max-w-[171px] w-43" />

        <img
          src="/success-sidebar-image.png"
          alt="desktop-image"
          className="lg:w-233px xxl:max-w-[432px]"
        />
      </div>
   
        {/* back button  */}
        {/* {isbackButtonShow && (
          <div
            className="fixed top-153px left-[200px] w-[84px] h-[48px] flex justify-center items-center gap-1 cursor-pointer"
            onClick={() => navigate('/dashboard')}>
            <MdOutlineKeyboardBackspace className="w-4 h-4" />
            <p className="font-bold text-[#000000]">Back</p>
          </div>
        )} */}
         <CenterWrapper>
        <div
          className={`w-[480px] h-full  flex flex-col gap-2 justify-center ${isError ? 'items-center bg-Error-300' : 'items-start'}`}>
          {isError ? (
            <RxCross2 className="text-[#F83A5D] w-10 h-10" />
          ) : (
            <img src="/done-icon.svg" alt="done icon" />
          )}
          <div className="flex flex-col gap-6">
            <h3
              className={`font-extrabold ${isError ? 'text-center text-Error-600' : 'text-Success-600'} text-3xl `}>
              {heading}
            </h3>
            <p
              className={`text-base ${isError ? 'text-center' : ''} font-normal text-[#6C6F71]`}>
              {subheading}
            </p>
            {paragraph && <p className='text-Neutral-900'>{paragraph}</p> }
          </div>
        </div>
        </CenterWrapper>
    </div>
  );
}

export default MessageWrapper;
