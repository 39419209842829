import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa6';
import { sidebarTabs, tabImages } from '../constants';

const Sidebar = ({
  highestFormIndexCompleted,
  selectedTab,
  setSelectedTab,
  activePaymentDetailsTab,
  setActivePaymentDetailsTab,
}) => {
  return (
    <div className="flex flex-col h-full w-full  px-10 pb-10 lg:px-16 lg:py-6 relative">
      <img src="/tittle-logo.svg" alt="EZ-CRM Logo" className="max-w-[171px] w-full" />
      {selectedTab !== 0 && (
        <div className="flex flex-col gap-4 lg:mt-12 mt-8">
          {sidebarTabs.map((item, index) => (
            <div key={index}>
              <div className="flex items-center gap-4 py-3 cursor-pointer relative">
                <div
                  className={`h-6 w-6 border-2  box-border rounded-full text-sm font-bold flex justify-center items-center ${selectedTab === index + 1 && highestFormIndexCompleted < index ? 'border-[#009DFF] text-[#009DFF]' : highestFormIndexCompleted >= index + 1 ? 'border-Success-600 text-white bg-Success-600' : 'border-Primary-500 text-Primary-500'}`}>
                  {' '}
                  {
                    selectedTab === index + 1 ? (
                      index + 1 // Show number for the currently selected tab
                    ) : highestFormIndexCompleted >= index + 1 ? (
                      <FaCheck /> // Show checkmark for completed tabs
                    ) : (
                      index + 1
                    ) // Show number for upcoming/incomplete tabs
                  }
                </div>

                <p
                  className={`text-base ${selectedTab === index + 1 ? 'text-Primary-500 font-bold' : ' text-Neutral-200 font-normal'}`}>
                  {item}
                </p>

                {selectedTab !== 4 && selectedTab === index + 1 && (
                  <img
                    src="/onboarding-sidebar-right-arrow.svg"
                    alt="Right-Arrow Icon"
                    className="h-6 w-6 hover:opacity-80 ml-auto"
                  />
                )}
              </div>

              {index === 3 && selectedTab === 4 && (
                <div className="flex flex-col">
                  <div className="flex justify-between items-center cursor-pointer">
                    <p
                      className={`pl-10 py-3 text-base ${selectedTab == index + 1 && activePaymentDetailsTab === 0 ? 'text-Neutral-900 font-bold' : 'text-Neutral-500 font-normal'}`}>
                      Select Method
                    </p>
                    {selectedTab == index + 1 && activePaymentDetailsTab === 0 && (
                      <img
                        src="./onboarding-sidebar-right-arrow.svg"
                        alt="Right-Arrow Icon"
                        className="h-6 w-6 hover:opacity-80"
                      />
                    )}
                  </div>

                  <div className="flex justify-between items-center h-[48px] cursor-pointer">
                    <p
                      className={`pl-10 py-3 text-base ${activePaymentDetailsTab >= 1 ? 'text-Neutral-900 font-bold' : 'text-Neutral-500 font-normal'}`}>
                      Method details
                    </p>
                    {activePaymentDetailsTab >= 1 && (
                      <img
                        src="./onboarding-sidebar-right-arrow.svg"
                        alt="Right-Arrow Icon"
                        className="h-6 w-6 hover:opacity-80"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      <img
        src={tabImages[selectedTab]?.icon}
        alt={tabImages[selectedTab]?.alt}
        className="w-full mt-auto"
      />
    </div>
  );
};

export default Sidebar;
