import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import MessageWrapper from '../common/MessageWrapper';
import SuccessMessage from '../message-pages/success-message';

function ProtectedRoutes({ children }) {
  const user = useSelector((state) => state.user);

  if (!user?.isLoggedIn) {
    return <Navigate to="/login" replace={true} />;
  }

  if (user?.roleId?.name === 'Admin') return children;

  if ('tokenAccepted' in user && !user.tokenAccepted) {
    console.log('inside token');
    return (
      <MessageWrapper
        heading={'The invitation is not Accepted.'}
        subheading={'Please accept our invitation first'}
        isError={true}
      />
    );
  }
  // Check for approval and render success message
  else if (
    'isApprove' in user &&
    !user.isApprove &&
    user.roleId?.name !== 'Master Distributor'
  ) {
    return <SuccessMessage />;
  }
  else if (!user.isInformationSubmitted && !user.isSkip) {
    if ( user?.roleId?.name === 'Authorized Distributor') {
      return <Navigate to="/ad" replace={true} />;
    }
    else return  <Navigate to="/md" replace={true} />;
  
  }

  // Render children if all checks are passed
  return children;
}

export default ProtectedRoutes;
