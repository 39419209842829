import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EditUserModal from '../popups/editUserModal';
import Utils from '../utility';
import { useDispatch, useSelector } from 'react-redux';
import { removeUser } from '../slices/userSlice';
import WalletService from '../services/wallet';

const UserProfileSidebar = ({ profileMenuOpen, toggleProfileSidebar, handleLogout }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const [walletDetails, setWalletDetails] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    async function fetchWalletDetails() {
      try {
        // CHECKING IF TOKEN IS EXPIRE OR NOT
        if (Utils.checkTokenExpireOrNot()) {
          dispatch(removeUser({}));
          return;
        }

        const walletDetails = await new WalletService().getUserWallet(user?._id);

        setWalletDetails(walletDetails);
      } catch (error) {
        console.error(error);
      }
    }
    fetchWalletDetails();
  }, [dispatch, user?._id]);

  const handleAddCash = () => {
    navigate('/add-money');
  };

  return (
    <>
      {showEditModal && <EditUserModal setShowEditModal={setShowEditModal} />}

      <div
        className={`fixed top-0 right-0 h-full bg-white shadow-lg font-jakarta transform transition-transform duration-300 ${
          profileMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } w-[300px] z-50 flex flex-col justify-between`}>
        <div className="h-full pt-4">
          <div className="flex justify-between px-4">
            <div className="text-[25px] font-jakarta font-semibold text-[#1D2224]">
              <p className="text-[#1D2224] text-[25px] font-extrabold">
                {user?.firstName
                  ? `${user?.firstName}' s Profile`
                  : user.roleId.name === 'Authorized Distributor'
                    ? "AD's Profile"
                    : "MD's Profile"}
              </p>
            </div>

            <button onClick={toggleProfileSidebar} className="text-gray-500 hover:text-gray-900">
              <img src="/close.svg" alt="Close" className="h-6 w-6" />
            </button>
          </div>
          <hr className="mt-8 border border-[#DDDEDE] mx-4" />

          <div className="calcHeight overflow-y-auto hide-scrollbar h-full py-8 px-4">
            {user && user.roleId && (
              <div className="text-[#064C64]  bg-[#C5EAF7] w-fit px-4 py-1 rounded-[16px]">
                {user.roleId.name}
              </div>
            )}

            <div className="flex mt-4 gap-1.5">
              {user && user?.image ? (
                <img
                  src={user?.image}
                  alt={user?.name}
                  className="w-[60px] h-[60px]  object-cover cursor-pointer"
                />
              ) : (
                <div className="w-[60px] h-[60px] bg-[#F1F2F2] rounded-full flex items-center justify-center">
                  {user?.name
                    ? `${user?.name.substring(0, 1).toUpperCase()}${user?.name
                        .split(' ')
                        .map((word) => word[0])
                        .join('')
                        .substring(1, 2)
                        .toUpperCase()}`
                    : ''}
                </div>
              )}

              <div>
                <h2 className="mt-2 text-ft13 text-[#1d2224] font-semibold">
                  {(user && user?.name) || 'W. Maximwolf'}
                </h2>
                <p className="text-[#5A5E5F] font-normal text-ft2">
                  {(user && user.email) || 'maximwolf@gmail.com'}
                </p>
              </div>
            </div>

            <button
              className="flex hover:bg-opacity-85  items-center mt-4 gap-1 bg-[#F1F2F2] px-6 py-3 rounded-[24px] w-fit"
              onClick={() => setShowEditModal(true)}>
              <img
                src="/edit-profile.svg"
                alt="User Profile"
                className="w-[22px] h-[20px] object-cover cursor-pointer"
              />
              <p className="text-[#1d2224] text-ft13  font-semibold">Edit Profile</p>
            </button>

            {user?.roleId?.name !== 'Admin' && (
              <button
                className="flex hover:bg-opacity-85  items-center mt-4 gap-1 bg-[#F1F2F2] px-6 py-3 rounded-[24px] w-fit"
                onClick={() =>
                  navigate('/enter-details')
                }>
                <img
                  src="/edit-profile.svg"
                  alt="User Profile"
                  className="w-[22px] h-[20px] object-cover cursor-pointer"
                />
                <p className="text-[#1d2224] text-ft13  font-semibold">Add Your Details</p>
              </button>
            )}

            <hr className="my-8 border border-[#DDDEDE]" />

            <div className="my-4 rounded-[24px] flex-col p-4 flex bg-[#F1F2F2]">
              <span className="flex flex-col gap-2">
                <p className=" text-[#6C6F71] text-ft1_5 uppercase">Balance:</p>
                <p className=" text-[20px]  font-extrabold">
                  {walletDetails?.balance === null ||
                  walletDetails?.balance === undefined ||
                  isNaN(walletDetails?.balance)
                    ? '0.00'
                    : new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(walletDetails?.balance)}
                </p>
              </span>

              {user?.roleId?.name === 'Authorized Distributor' && (
                <>
                  <p className="mt-4 font-normal text-[#6C6F71]">
                    Last Deposit:{' '}
                    {walletDetails?.lastDeposit === null ||
                    walletDetails?.lastDeposit === undefined ||
                    isNaN(walletDetails?.lastDeposit)
                      ? '0.00'
                      : new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(walletDetails?.lastDeposit)}
                  </p>
                  <button
                    onClick={handleAddCash}
                    className="mt-4 w-[236px] px-4 py-3 bg-[#0EA8DE] hover:bg-opacity-85 text-white rounded-[24px]">
                    Add Cash
                  </button>
                </>
              )}

              {/* <Link
                to="/transactions"
                className="bg-[#DDDEDE] w-[236px] px-4 py-3 mt-2 hover:bg-opacity-85 rounded-[24px] flex justify-center items-center "
              >
                <p className="text-[#1d2224] font-semibold text-[16px]">
                  View Transactions
                </p>
              </Link> */}
            </div>

            <button
              onClick={handleLogout}
              className="bg-[#FBDEDF] hover:bg-opacity-85 w-full gap-1 p-4 rounded-[24px] justify-center items-center flex">
              <img src="/logout.svg" alt="logout" className="" />
              <p className="text-[#E32028] font-semibold text-[16px]">Logout</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfileSidebar;
