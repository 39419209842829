import React, { useEffect, useRef, useState } from 'react';
import { saveAs } from 'file-saver';
import DistributorDetails from '../popups/DMdetailsModal';
import AuthorizedDistributorDetails from '../popups/adDetailsModal';
import PendingAD from '../popups/pendingAD';
import { BiExport } from 'react-icons/bi';
import { TbLoader3 } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
// import UserService from "../services/user";

const ADTableComp = ({
  distributors,
  setDistributors,
  subscribersLoading,
  user,
  totalDistributors,
  pagination,
  setPagination,
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });
  const [selectedDistributor, setSelectedDistributor] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [paginationDropdownOpen, setPaginationDropdownOpen] = useState(false);
  const paginationRef = useRef(null);

  useEffect(() => {
    let handler = (e) => {
      if (paginationRef.current && !paginationRef.current.contains(e.target)) {
        setPaginationDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedDistributors = Array.isArray(distributors)
    ? [...distributors].sort((a, b) => {
        if (!sortConfig.key) return 0;
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      })
    : [];

  const filteredDistributors =
    Array.isArray(distributors) && distributors.length
      ? sortedDistributors.filter(
          (distributor) =>
            distributor.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            distributor.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            distributor.status?.toLowerCase().includes(searchTerm?.toLowerCase())
        )
      : [];

  const handleNextPage = () => {
    if (pagination.currentPage * pagination.rowsPerPage < totalDistributors) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };

  const handlePrevPage = () => {
    if (pagination.currentPage > 1) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const exportToCSV = () => {
    const headers = ['Name', 'Email', 'Status', 'Active Subscribers', 'Commissions'];
    const rows = filteredDistributors.map((dist) => [
      dist.name,
      dist.email,
      dist.status,
      dist.activeSubscribers || 0,
      dist.myCommissions || 0.0,
    ]);
    const csvContent = [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'distributors.csv');
  };

  const handleRowsPerPageChange = (event) => {
    setPagination({
      ...pagination,
      rowsPerPage: Number(event.target.value),
      currentPage: 1,
    });
  };

  const handleRowClick = (distributor) => {
    if (distributor?.status === 'Active') {
      setSelectedDistributor(distributor);
    }
  };

  const closeModal = () => {
    setSelectedDistributor(null);
  };

  return (
    <div className="p-4 font-jakarta">
      <div className="flex justify-between">
        <h2 className="text-[25px] font-bold mb-4">All Authorized Distributors</h2>
        <div className="flex items-center justify-center bg-[#DDDEDE] hover:bg-opacity-85 rounded-[24px] text-[#1D2224] px-4 gap-2 py-2 h-12">
          <BiExport className="size-6" />
          <button onClick={exportToCSV}>Export To CSV</button>
        </div>
      </div>

      <div className={`overflow-x-auto relative`}>
        <table className="w-full border-collapse">
          <thead className="bg-[#F1F2F2]">
            <tr className="text-ft1_5 font-normal text-[#6C6F71] uppercase">
              <th className="text-left p-2 cursor-pointer" onClick={() => requestSort('name')}>
                Name
              </th>
              <th className="text-left p-2 cursor-pointer" onClick={() => requestSort('email')}>
                Email
              </th>
              <th className="text-left p-2 cursor-pointer" onClick={() => requestSort('status')}>
                Status
              </th>
              <th
                className="text-left p-2 cursor-pointer"
                onClick={() => requestSort('activeSubscribers')}>
                Active Subscribers
              </th>
              <th
                className="text-left p-2 cursor-pointer"
                onClick={() => requestSort('myCommissions')}>
                Commission
              </th>
            </tr>
          </thead>

          {subscribersLoading ? (
            <div className="flex justify-center items-center h-80">
              <TbLoader3 className="animate-spin text-black h-13 w-13 absolute left-45per top-45per" />
            </div>
          ) : (
            <tbody>
              {filteredDistributors.length > 0 ? (
                filteredDistributors.map((distributor, index) => (
                  <tr
                    key={index}
                    className={`${distributor.status !== 'Pending' ? 'hover:bg-white hover:rounded-[28px] hover:cursor-pointer' : ''}`}
                    onClick={() => handleRowClick(distributor)}>
                    <td className="py-4 px-2">
                      {distributor?.firstName
                        ? distributor?.firstName + ' ' + distributor?.lastName
                        : 'N/A'}
                    </td>
                    <td className="py-4 px-2">{distributor.email}</td>
                    <td className="py-4 px-2">
                      <span
                        className={`px-2 py-1 rounded-full font-normal capitalize ${
                          distributor.status === 'active'
                            ? 'bg-[#D1FBEE] text-[#0C7B59]'
                            : distributor.status === 'pending'
                              ? 'bg-[#FBDEDF] text-[#B3161D]'
                              : 'bg-[#DDDEDE] text-[#6C6F71]'
                        }`}>
                        {distributor?.status
                          ? distributor.status === 'information_submitted'
                            ? 'Information Submitted'
                            : distributor.status === 'pending'
                              ? 'Pending Approval'
                              : distributor.status === 'active'
                                ? 'Active'
                                : distributor.status
                          : 'Inactive'}
                      </span>
                    </td>
                    <td className="py-4 px-2">{distributor.activeSubscribers || 0}</td>
                    <td className="py-4 px-2">
                      $
                      {distributor.myCommissions !== undefined && distributor.myCommissions !== null
                        ? distributor.myCommissions.toFixed(2)
                        : '0.00'}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-3">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
      <div className="flex justify-end items-center mt-4">
        <div className="flex items-center gap-10">
          <div className="flex items-center">
            <span>Rows per page:</span>
            <div
              className="relative"
              onClick={() => setPaginationDropdownOpen(!paginationDropdownOpen)}
              ref={paginationRef}>
              <img
                src="./chevron_backward.svg"
                alt="Chevron-Down Icon"
                className={`absolute right-[15px] top-[17px] transition-all ${paginationDropdownOpen ? 'rotate-180' : ''}`}
                style={{ transitionDuration: '100ms' }}
              />
              <select
                value={pagination.rowsPerPage}
                onChange={handleRowsPerPageChange}
                className="ml-2 py-2 pl-[16px] w-[68px] h-[40px] outline-none border-none rounded-[24px] appearance-none">
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            </div>
          </div>
          <span>
            {(pagination.currentPage - 1) * pagination.rowsPerPage + 1} -{' '}
            {pagination.currentPage * pagination.rowsPerPage > totalDistributors
              ? totalDistributors
              : pagination.currentPage * pagination.rowsPerPage}{' '}
            of {totalDistributors}
          </span>
          <div>
            <button className="p-2 rounded-lg mr-2" onClick={handlePrevPage}>
              &lt;
            </button>
            <button className="p-2 rounded-lg" onClick={handleNextPage}>
              &gt;
            </button>
          </div>
        </div>
      </div>

      {selectedDistributor && user?.roleId?.name === 'Master Distributor' && (
        <DistributorDetails
          onClose={closeModal}
          selectedDistributor={selectedDistributor}
          distributors={distributors}
          setDistributors={setDistributors}
        />
      )}

      {selectedDistributor && user?.roleId?.name === 'Authorized Distributor' && (
        <AuthorizedDistributorDetails
          onClose={closeModal}
          selectedDistributor={selectedDistributor}
          distributors={distributors}
          setDistributors={setDistributors}
        />
      )}

      {selectedDistributor && selectedDistributor.status === 'Pending' && (
        <PendingAD onClose={closeModal} distributor={selectedDistributor} />
      )}
    </div>
  );
};

export default ADTableComp;
