import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ collapsed, toggleSidebar, selectedAdminSubmenu, setSelectedAdminSubmenu }) => {
  const user = useSelector((state) => state.user);
  const [selected, setSelected] = useState(
    selectedAdminSubmenu
      ? selectedAdminSubmenu
      : window.location.pathname === '/earning'
        ? 'EZ Earnings'
        : window.location.pathname === '/product'
          ? 'Product Catalogue'
          : user?.roleId?.name === 'Admin'
            ? selectedAdminSubmenu
            : user?.roleId?.name === 'Master Distributor'
              ? 'AD Management'
              : 'Subscriber Management'
  );
  const navigate = useNavigate();
  console.log('selected', selected);

  useEffect(() => {
    if (window.location.pathname === '/dashboard') {
      setSelected(selectedAdminSubmenu);
      localStorage.setItem('selectedAdminSubmenu', selectedAdminSubmenu);
    }
  }, [selectedAdminSubmenu]);
  const roleBasedMenus = {
    'Authorized Distributor': [
      {
        title: 'Dashboard',
        blackIcon: './dashboard-tab-icon-black.svg',
        greyIcon: './dashboard-tab-icon-grey.svg',
        path: '/dashboard',
      },
      {
        title: 'Subscribers',
        blackIcon: './subscribers-tab-icon-black.svg',
        greyIcon: './subscribers-tab-icon-grey.svg',
        path: '/dashboard',
      },
      {
        title: 'EZ Earnings',
        blackIcon: './earnings-tab-icon-black.svg',
        greyIcon: './earnings-tab-icon-grey.svg',
        path: '/earning',
      },
      {
        title: 'Product Catalogue',
        blackIcon: './products-tab-icon-black.svg',
        greyIcon: './products-tab-icon-grey.svg',
        path: '/product',
      },
    ],
    'Master Distributor': [
      {
        title: 'Dashboard',
        blackIcon: './dashboard-tab-icon-black.svg',
        greyIcon: './dashboard-tab-icon-grey.svg',
        path: '/dashboard',
      },
      {
        title: 'Subscribers',
        blackIcon: './subscribers-tab-icon-black.svg',
        greyIcon: './subscribers-tab-icon-grey.svg',
        path: '/dashboard',
      },
      {
        title: 'EZ Earnings',
        blackIcon: './earnings-tab-icon-black.svg',
        greyIcon: './earnings-tab-icon-grey.svg',
        path: '/earning',
      },
      {
        title: 'Product Catalogue',
        blackIcon: './products-tab-icon-black.svg',
        greyIcon: './products-tab-icon-grey.svg',
        path: '/product',
      },
    ],
    Admin: [
      {
        title: 'Dashboard',
        blackIcon: './dashboard-tab-icon-black.svg',
        greyIcon: './dashboard-tab-icon-grey.svg',
        path: '/dashboard',
      },
      {
        title: 'Subscribers',
        blackIcon: './subscribers-tab-icon-black.svg',
        greyIcon: './subscribers-tab-icon-grey.svg',
        path: '/dashboard',
      },
      {
        title: 'EZ Earnings',
        blackIcon: './earnings-tab-icon-black.svg',
        greyIcon: './earnings-tab-icon-grey.svg',
        path: '/earning',
      },
      {
        title: 'Product Catalogue',
        blackIcon: './products-tab-icon-black.svg',
        greyIcon: './products-tab-icon-grey.svg',
        path: '/product',
      },
    ],
  };

  const menuItems = roleBasedMenus[user?.roleId?.name] || [];

  const handleMenuClick = (item) => {
    setSelected(item.name);
    localStorage.setItem('selectedAdminSubmenu', item.name);
    if (item.name !== 'Product Catalogue') {
      setSelectedAdminSubmenu(item.name);
    }
    navigate(item.path);
  };

  return (
    <div
      className={`sticky  left-0 bg-white z-40 font-jakarta p-3 h-screen transition-all duration-300 ${collapsed ? 'w-[100px]' : 'w-[280px]'}`}>
      <div className={`flex items-center mb-8 px-3  ${collapsed ? 'justify-center' : ''}`}>
        <img
          src={collapsed ? '/EZ Mobile-logo.svg' : '/tittle-logo.svg'}
          alt="EZMobile Logo"
          className="h-8"
        />
      </div>
      <ul className={`flex flex-col gap-3 ${collapsed ? 'items-center' : ''}`}>
        {menuItems.map((item, index) => (
          <li
            key={item.title}
            onClick={() => handleMenuClick(item)}
            className={`flex items-center p-3 gap-2 justify-start  rounded-xl cursor-pointer hover:bg-Primary-50 ${
              selected === item.title
                ? 'bg-Primary-100 text-Neutral-700 font-bold'
                : 'bg-white text-Neutral-600 font-normal'
            } ${collapsed ? 'justify-center' : 'w-full'}`}>
            <img
              src={selected === item.title ? item.blackIcon : item.greyIcon}
              alt={`${item.title} Icon`}
              className="w-6 h-6"
            />
            {!collapsed && (
              <>
                <p className={`whitespace-nowrap`}>{item.title}</p>

                {item.hasNotification && (
                  <span className="ml-auto w-2.5 h-2.5 bg-red-500 rounded-full"></span>
                )}
              </>
            )}
          </li>
        ))}
      </ul>
      <button
        className={`flex p-3 gap-2 bg-Primary-200 rounded-[45px] absolute bottom-3 ${collapsed ? 'left-1/2 translate-x-[-50%]': 'left-3'} `}
        onClick={toggleSidebar}>
        <div className='flex items-center justify-center px-1 py-[6px] w-6 h-6'>
        <img
          src={'/arrow-back-icon.svg'}
          alt="Collapse Icon"
          className={` ${collapsed ? 'rotate-180': 'rotate-0'}`}
        />
        </div>
       
        {!collapsed && <p className="text-Neutral-900 w-44 text-left">Collapse</p>}
      </button>
    </div>
  );
};

export default Sidebar;
